import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "links" }
const _hoisted_2 = { class: "controls" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Score = _resolveComponent("Score")!
  const _component_Prize = _resolveComponent("Prize")!
  const _component_LocalePicker = _resolveComponent("LocalePicker")!
  const _component_ThemePicker = _resolveComponent("ThemePicker")!

  return (_openBlock(), _createElementBlock("nav", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_link, { to: "/" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("pages.Home.pageTitle")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, { to: "/about" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("pages.About.pageTitle")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, { to: "/experience" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("pages.Experience.pageTitle")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, { to: "/contact" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("pages.Contact.pageTitle")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, { to: "/things" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("pages.Things.pageTitle")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, { to: "/links" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("pages.Links.pageTitle")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_Score),
      _createVNode(_component_Prize)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_LocalePicker),
      _createVNode(_component_ThemePicker)
    ])
  ]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_PixelGame = _resolveComponent("PixelGame")!
  const _component_FootBar = _resolveComponent("FootBar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NavBar),
    _createElementVNode("main", null, [
      _createVNode(_component_router_view),
      _createVNode(_component_PixelGame)
    ]),
    _createVNode(_component_FootBar)
  ], 64))
}
<template>
  <footer></footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "FootBar"
});
</script>

<style lang="scss">
footer {
  height: 66px;
  padding: 0 22px;
  flex-shrink: 0;
}
</style>

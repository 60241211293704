import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "themes" }
const _hoisted_2 = { for: "themeSelect" }
const _hoisted_3 = ["value"]
const _hoisted_4 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t("components.ThemePicker.themeLabel")), 1),
    _createElementVNode("select", {
      id: "themeSelect",
      value: _ctx.uiStore.theme,
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleThemeChange && _ctx.handleThemeChange(...args)))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.uiStore.themes, (theme) => {
        return (_openBlock(), _createElementBlock("option", {
          key: theme,
          value: theme
        }, _toDisplayString(_ctx.$t(`components.ThemePicker.${theme}`)), 9, _hoisted_4))
      }), 128))
    ], 40, _hoisted_3)
  ]))
}
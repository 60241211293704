import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    "aria-hidden": "true",
    class: "pixel",
    style: _normalizeStyle(_ctx.position),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
  }, [
    _createElementVNode("div", {
      class: "color",
      style: _normalizeStyle(_ctx.backgroundColor)
    }, null, 4)
  ], 4))
}
<template>
  <h2>Blog Post One</h2>
  <time datetime="2022-07-04">July 4th 2022</time>
  <p>By Jacob Sullivan</p>
  <p>
    This is the first paragraph. I'm writing the first paragraph so I can see what it looks like on the blog page and
    style it. Let's make the first paragraph a decent length. Maybe 5 sentences, or 6 even. Should I write out five and
    six? Or leave it as 5 and 6. Hit that like button and comment below on how to write out digits in this context.
  </p>
  <p>
    This is the second paragraph. I'm not sure if I want to write two or three paragraphs. Or is it 2 or 3 paragraphs?
    It will be obvious to you because you will see the number of paragraphs and be able to tell at a glance if there are
    two or three or more or fewer.
  </p>
  <p>
    This is the third paragraph, or is it the 3rd paragraph. I'm watching a video on conical burr grinders. I want to
    get a nice one but they are exensive and I want to save money. I already ordered some clothes today that I didn't
    really need but I kind of needed.
  </p>
  <p>This will be the last paragraph. I'll keep it short and sweet.</p>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "July4th2022"
});
</script>

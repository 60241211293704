import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "links" }
const _hoisted_2 = { class: "youtube-links" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.$t("pages.Links.pageTitle")), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t("pages.Links.youtube")), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.youtubeChannels, (category) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "youtube-category",
          key: category.category
        }, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t(`pages.Links.${category.category}`)), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(category.channels, (channel) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "youtube-link",
              key: channel.channel
            }, [
              _createElementVNode("a", {
                href: channel.channel,
                rel: "noopener noreferrer",
                target: "_blank"
              }, _toDisplayString(channel.channel), 9, _hoisted_3),
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t(channel.description)), 1)
            ]))
          }), 128))
        ]))
      }), 128))
    ])
  ]))
}
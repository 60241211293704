import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  "aria-label": "Score of hidden pixel game"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.gameStore.pixel.score > 0)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.gameStore.pixel.score), 1))
    : _createCommentVNode("", true)
}
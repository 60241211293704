import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.showPrize)
    ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        role: "img",
        "aria-label": _ctx.$t('components.Prize.prizeLabel')
      }, _toDisplayString(_ctx.prize), 9, _hoisted_1))
    : _createCommentVNode("", true)
}
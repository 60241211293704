import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "about" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.$t("pages.About.pageTitle")), 1),
    _createElementVNode("p", null, _toDisplayString(_ctx.$t("pages.About.firstParagraph")), 1),
    _createElementVNode("p", null, _toDisplayString(_ctx.$t("pages.About.secondParagraph")), 1),
    _createElementVNode("p", null, _toDisplayString(_ctx.$t("pages.About.thirdParagraph")), 1),
    _createElementVNode("p", null, _toDisplayString(_ctx.$t("pages.About.fourthParagraph")), 1),
    _createElementVNode("p", null, _toDisplayString(_ctx.$t("pages.About.fifthParagraph")), 1)
  ]))
}